import TagManager from 'react-gtm-module'
import { useSearchParams } from 'react-router-dom'
import logo from 'src/assets/images/logo.png'
import people from 'src/assets/images/people.svg'
import star from 'src/assets/images/star.svg'
import { FINES_APP_LINK } from 'src/constants'
import styles from './FinesAppLanding.module.scss'

export const FinesAppLanding = () => {
  const [searchParams] = useSearchParams()

  const params = {
    campaign: `campaign=${searchParams.get('utm_campaign') || 'landing'}`,
    creative: searchParams.get('utm_term') ? `&creative=${searchParams.get('utm_term')}` : '',
    yaClickId: searchParams.get('yclid') ? `&ya_click_id=${searchParams.get('yclid')}` : ''
  }

  const handleClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Click'
      }
    })
  }

  return (
    <a
      className={styles.container}
      href={`${FINES_APP_LINK}?${params.campaign}${params.creative}${params.yaClickId}&idfa={ios_ifa}`}
      onClick={handleClick}
    >
      <div className={styles.content}>
        <img alt="Логотип приложения" className={styles.logo} src={logo} />
        <p className={styles.title}>
          Штрафы ГИБДД <br />
          официальные
        </p>

        <div className={styles.stats}>
          <div>
            <div className={styles.info}>
              <span className={styles.amount}>4.9</span>
              <div className={styles.stars}>
                {Array.from({ length: 5 }).map((_, i) => (
                  <img key={i} alt="" src={star} />
                ))}
              </div>
            </div>
            <p className={styles.description}>542 тыс. оценок</p>
          </div>

          <div>
            <div className={styles.info}>
              <span className={styles.amount}>10 млн</span>
              <img alt="" className={styles.peopleIcon} src={people} />
            </div>
            <p className={styles.description}>пользователей</p>
          </div>
        </div>
      </div>

      <div className={styles.button}>Установить</div>
    </a>
  )
}
