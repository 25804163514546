import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { FinesAppLanding } from './components/FinesAppLanding'
import { GTM_ID } from './constants'

export const App = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID
    })
  }, [])

  return <FinesAppLanding />
}
